



































































































































































import ImportProfileTs from './ImportProfileTs';
export default class ImportProfile extends ImportProfileTs {}
